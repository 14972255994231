import Vue from "vue";
import Vuex from "vuex";
import menu from "./modules/menu.js";
import login from "./modules/login.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {menu,login},
});
