export default [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/home/home.vue"),
    redirect: {
      name: "index"
    },
    children: [
      {
        path: "/",
        name: "index",
        component: () => import("@/views/index/index.vue")
      },
      {
        path: "/article",
        name: "article",
        component: () => import("@/views/article/article.vue")
      },
      {
        path: "/article/detail",
        name: "detail",
        component: () => import("@/views/article/detail.vue")
      },
      {
        path: "/message",
        name: "message",
        component: () => import("@/views/message/message.vue")
      },
      {
        path: "/archive",
        name: "archive",
        component: () => import("@/views/archive/archive.vue")
      },
      {
        path: "/about",
        name: "about",
        component: () => import("@/views/about/about.vue")
      },
      {
        path: "/nav",
        name: "nav",
        component: () => import("@/views/nav/nav.vue")
      }
    ]
  },
  {
    path: "/auth",
    name: "auth",
    component: () => import("@/views/auth/auth.vue")
  }
];
