import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./assets/styles/reset.less";
import "./assets/styles/styles.less";
import "./assets/iconfont/iconfont.css";
import "./element.js";

import * as filters from "@/filters/index.js";
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

import Api from "@/http/api.js";
Vue.prototype.$api = Api;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
