import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import routes from "./routes";

Vue.use(VueRouter);


const router = new VueRouter({
  mode: "hash", // ['history', 'hash']
  linkActiveClass: "active", // active class 名称
  scrollBehavior(to, from, savedPosition) {
    // 后退页面时, 保留滚动位置
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes
});

export default router;
